@font-face {
  font-family: 'Raleway';
  font-weight: 600;
  src: url('./assets/fonts/Raleway-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 700;
  src: url('./assets/fonts/Raleway-Bold.ttf') format('truetype');
}

html, body {
  font-family: 'Raleway', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

@media (max-width: 768px) {
  html,body {
    overflow-x :hidden;
  }
}
.careers-container {
    max-width: 900px;
    text-align: left;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .careers-title {
    font-size: 2.5em;
    color: #ff5252;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .careers-intro {
    font-size: 1.2em;
    color: #444;
    margin-bottom: 30px;
    text-align: left;
  }
  
  .career-section {
    margin-top: 40px;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 12px;
  }
  
  .section-title {
    font-size: 2em;
    color: #333;
    margin-bottom: 15px;
    font-weight: 500;
    border-bottom: 2px solid #ff5252;
    padding-bottom: 8px;
  }
  
  .section-description {
    font-size: 1.1em;
    color: #555;
    margin-bottom: 20px;
  }
  
  .learn-more-button {
    background-color: #ff5252;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .learn-more-button:hover {
    background-color: #e84141;
    transform: translateY(-2px);
  }
  
.footer {
    padding: 20px 40px;
    background-color: #333;
    color: #fff;
    text-align: center;
    position: relative;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .footer p {
    margin: 10px 0;
    font-size: 1rem;
  }
  
  .download-links, .social-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
  }
  
  .download-link, .social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .footer-store-logo{
    width: 200px;
    height: auto;
  }
  
.footer-social-icon {
    width: 40px;
    height: auto;
  }

  .coming-soon {
    position: relative;
  }
  
  .coming-soon-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2px 5px;
    border-radius: 3px;
  }
  
  @media (max-width: 768px) {
    .download-links, .social-links {
      gap: 10px;
    }
  
    .footer-store-logo, .footer-social-icon {
      width: 40px;
    }
  }
  
body {
  font-family: 'Raleway', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.how-it-works {
  padding: 80px 20px;
  text-align: center;
  background-color: #ff5252;
  color: white;
}

.how-it-works h2 {
  font-size: 3rem;
  margin-bottom: 20px;
  color: white;
  font-weight: bold;
}

.intro-text {
  font-size: 1.5rem;
  margin-bottom: 60px;
  color: rgb(231, 229, 229);
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left; /* Align text to the left */
  max-width: 800px;
  margin: 40px;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  transition: transform 0.3s ease-in-out;
  background-color: #f9a0a0;
  border-radius: 20px;
}

.step:hover {
  transform: translateY(-10px);
}

.step.in-view {
  opacity: 1;
  transform: translateY(0);
}

.step:nth-child(even) {
  flex-direction: column-reverse;
}

.step-text {
  flex: 1;
  padding: 20px;
  text-align: left; /* Align text to the left */
}

.step-text h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: black;
  font-weight: 600;
}

.step-text p {
  font-size: 1.25rem;
  color: #555;
}

.step-image {
  width: 70%;
  max-width: 400px;
  height: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Arrow animation */
.arrow-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.arrow-dot-1, .arrow-line, .arrow-dot-2 {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  margin: 4px 0;
}

.arrow-head {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid white;
  margin-top: 4px;
  animation: move 2.5s infinite;
}

@keyframes move {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@media (min-width: 768px) {
  .steps {
    flex-direction: column;
  }

  .step {
    flex-direction: row;
    text-align: left;
    gap: 20px;
    justify-content: center;
  }

  .step:nth-child(even) {
    flex-direction: row-reverse;
  }

  .step-text {
    text-align: left;
    padding: 0 20px;
  }

  .step-image {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .arrow-container {
    display: none;
  }

  .step {
    margin: 20px 10px;
    padding: 10px;
  }

  .step-image {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .step-text h3 {
    font-size: 1.25rem;
  }

  .step-text p {
    font-size: 1rem;
  }

  .how-it-works h2 {
    font-size: 2rem;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-title {
  font-size: 1.8em;
  font-weight: bold;
}

.title-link {
  color: #ff5252;
  text-decoration: none;
  transition: color 0.3s ease;
}

.title-link:hover {
  color: #e84141;
}

.nav {
  display: flex;
  gap: 20px;
}

.nav a {
  font-size: 1.1em;
  color: #333;
  text-decoration: none;
  padding: 8px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav a:hover {
  background-color: #ff5252;
  color: white;
}

.nav a:focus, .title-link:focus {
  outline: none;
  background-color: #ff5252;
  color: white;
}

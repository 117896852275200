.hero-section {
  position: relative;
  height: 120vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
}

.parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: relative;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* rgba values for overlay color */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: fadeIn 1.5s ease-in-out;
}

.hero-content {
  max-width: 800px;
  margin: 0 20px;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.app-logo {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
  animation: fadeIn 1.5s ease-in-out;
}

.hero-content .main-heading {
  font-size: 3.5rem;
  margin-bottom: 20px;
  font-weight: bold;
  line-height: 1.2;
  animation: slideInUp 1s ease-in-out;
}

.hero-content .sub-heading {
  font-size: 2.5rem;
  margin-top: 10px;
  visibility: hidden;
  animation: slideInUp 1s ease-in-out 1s forwards; /* Delay the animation of sub-heading */
}

.download-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 70px;
  margin-bottom: 70px;
  animation: fadeIn 2s ease-in-out;
}

.download-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.store-logo {
  width: auto;
  height: 70px;
}

.coming-soon {
  position: relative;
}

.coming-soon-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 0.9rem;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
}

.cta-button {
  display: none; /* Hide the old button */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInUp {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .hero-section {
    height: auto; /* Adjust height for mobile */
    padding: 20px; /* Add padding for better mobile spacing */
    background-color: #ff5252; /* Solid background color for mobile */
  }

  .parallax {
    background: none; /* Remove background image for mobile */
  }

  .hero-overlay {
    position: relative; /* Remove absolute positioning */
    background-color: rgba(0, 0, 0, 0.5); /* rgba values for overlay color */
  }

  .hero-content {
    width: 100%;
    padding: 20px;
    text-shadow: none; /* Remove text shadow for better readability */
  }

  .hero-content .main-heading {
    font-size: 2.75rem;
  }

  .hero-content .sub-heading {
    font-size: 1.75rem;
  }

  .store-logo {
    width: auto;
    height: 70px;
  }

  .download-buttons {
    flex-direction: column; /* Make buttons vertical */
    gap: 10px; /* Adjust gap between buttons */
  }
}

.ambassador-container {
    text-align: left;
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    flex-direction: row;
  }
  
  .sidebar {
    flex: 1;
    margin-right: 20px;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 12px;
    box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.05);
  }
  
  .section-menu {
    list-style: none;
    padding: 0;
  }
  
  .section-menu li {
    padding: 10px 15px;
    margin-bottom: 10px;
    cursor: pointer;
    color: #333;
    font-weight: 500;
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s;
    border-left: 4px solid transparent;
  }
  
  .section-menu li.active,
  .section-menu li:hover {
    background-color: #ff5252;
    color: white;
    border-left: 4px solid #ff5252;
  }
  
  .content {
    flex: 3;
    padding: 20px;
  }
  
  .section-title {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
    font-weight: 500;
    border-bottom: 2px solid #ff5252;
    padding-bottom: 5px;
  }
  
  .sub-section-title {
    font-size: 1.4em;
    color: #ff5252;
    margin-top: 20px;
    font-weight: 500;
  }
  
  .objectives-list, .structure-list, .nested-list {
    list-style-type: disc;
    margin-left: 20px;
    color: #444;
    font-size: 1.1em;
    line-height: 1.6em;
  }
  
  .objectives-list li, .structure-list li, .nested-list li {
    margin-bottom: 10px;
  }
  
  .application-link {
    color: #ff5252;
    text-decoration: none;
    font-weight: 500;
    border-bottom: 1px solid transparent;
    transition: all 0.3s ease;
  }
  
  .application-link:hover {
    color: #e84141;
    border-bottom: 1px solid #ff5252;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 768px) {
    .ambassador-container {
      flex-direction: column;
      padding: 10px;
    }
  
    .sidebar {
      margin-right: 0;
      margin-bottom: 20px;
      padding: 15px;
    }
  
    .section-menu li {
      padding: 8px 12px;
      font-size: 1em;
    }
  
    .content {
      padding: 15px;
    }
  
    .section-title {
      font-size: 1.8em;
    }
  
    .sub-section-title {
      font-size: 1.3em;
    }
  
    .objectives-list, .structure-list, .nested-list {
      font-size: 1em;
      line-height: 1.5em;
    }
  }
  
  @media (max-width: 480px) {
    .section-title {
      font-size: 1.5em;
    }
  
    .sub-section-title {
      font-size: 1.2em;
    }
  
    .sidebar {
      padding: 10px;
    }
  
    .content {
      padding: 10px;
    }
  
    .section-menu li {
      padding: 8px;
      font-size: 0.9em;
    }
  }
  
.download {
  padding: 25px 25px;
  background-color: #f4f4f9;
  text-align: center;
}

.download-content {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}

.download-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.download-link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.download-store-logo {
  width: auto;
  height: 70px;
}

.coming-soon {
  position: relative;
}

.coming-soon-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 4rem;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .download-links {
    flex-direction: column;
    gap: 10px;
  }

  .download-store-logo {
    width: auto;
    height: 70px;
  }
}

.partner-container {
    text-align: left;
    max-width: 900px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .partner-title {
    font-size: 2.5em;
    color: #ff5252;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .partner-intro {
    font-size: 1.2em;
    color: #444;
    margin-bottom: 30px;
    text-align: left;
  }
  
  .partner-section {
    margin-top: 40px;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 12px;
  }
  
  .section-title {
    font-size: 2em;
    color: #333;
    margin-bottom: 15px;
    font-weight: 500;
    border-bottom: 2px solid #ff5252;
    padding-bottom: 8px;
  }
  
  .benefits-list, .steps-list {
    list-style-type: disc;
    margin-left: 20px;
    color: #555;
    font-size: 1.1em;
    line-height: 1.6em;
  }
  
  .benefits-list li, .steps-list li {
    margin-bottom: 10px;
  }
  
  .steps-list {
    list-style-type: decimal;
  }
  
  .section-description {
    font-size: 1.1em;
    color: #555;
    margin-bottom: 20px;
  }
  
  .contact-info {
    margin-top: 40px;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 12px;
  }
  
  .contact-title {
    font-size: 1.5em;
    color: #ff5252;
    margin-bottom: 10px;
  }
  
  .contact-link {
    color: #ff5252;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .contact-link:hover {
    color: #e84141;
  }
  
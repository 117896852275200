.introduction-section {
    padding: 60px 20px;
    background-color: #f4f4f9;
    text-align: left;
  }
  
  .intro-content {
    max-width: 900px;
    margin: 0 auto;
    padding: 40px;
  }
  
  .intro-content h2 {
    font-size: 2rem;
    color: #333;
    margin: 0;
    line-height: 1.4;
  }

    
  .intro-content p {
    font-size: 1.5rem;
    color: #333;
    margin: 0;
    line-height: 1.4;
  }
  